
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root{
    --primary-black:#222222;
    --primary-orange:#FF8D1C; 
    --primary-gray:#696969;
    --primary-gradient:linear-gradient(180deg, rgb(254 142 30) 0%, rgb(239 102 80) 100%);
    --second-gradient: linear-gradient(to right,#458BFA 0%,#2962CE 100%);
}
:root{
    --common-font:'Roboto', sans-serif;
    --main-font:'Raleway', sans-serif;
}
*{margin:0; padding:0; outline:none; border:none;}
body { 
font-family: var(--common-font);

}
a {
    text-decoration: none;
    cursor: pointer;
}

a:hover,
a:focus,
a:visited {
    text-decoration: none !important;
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    position: relative;
    font-weight: normal;
    line-height: 1.25em;
    margin: 0px;
    background: none;
}

textarea {
    overflow: hidden;
}

button {
    outline: none !important;
    cursor: pointer;
}
.form-control:focus{
    box-shadow: none !important;
}
.forgot .form-control{
    text-transform: none;
}
.btn-outline-primary.focus, .btn-outline-primary:focus {
    box-shadow: none !important;
}
.btn-primary.focus, .btn-primary:focus{
    box-shadow: none !important;
}
.type_a{
    cursor: pointer;
}
/* //////////////////// Navbar //////////////////// */
nav.navbar{
    z-index: 9999;
    background: #fff !important;
    /* box-shadow: 8px 3px 30px 5px rgb(0 0 0 / 8%);
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s; */
    padding: 0;

}
nav a.nav-link{
    font-size: 1rem;
    letter-spacing: 0.0375rem;
    font-weight: 400;
}
.navbar-nav > li > a {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    color: var(--primary-black) !important ;
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 3rem;
}
.navbar-nav > li.active a, .navbar-nav > li.active:focus a{
    color: var(--primary-blue) !important;
}
.navbar-nav > li > a:hover{
    color: var(--primary-blue) !important;
}


/* //////////////////// Banner //////////////////// */

.ban{
    position: absolute;
    left: -1.25rem;
    top: 0.875rem;
}
.ban2{
    position: absolute;
    left: -3.5rem;
    top: 1.75rem;
}
.ban3{
    position: absolute;
    left: 40%;
    top: 20%;
}
.ban4{
    position: absolute;
    left: 48%;
    top: 70%;
}
.ban5{
    position: absolute;
    left: 20%;
    bottom: 0;
}
.textbanner{
    padding: 30% 0 0 25%;
}
.textbanner h2{
font-size: 1.125rem;
font-weight: 600;
color: var(--primary-blue);
text-align: left;
line-height: 2rem;

}
.textbanner h1{
    font-size: 3.125rem;
    line-height: 4.25rem;
    font-weight: 700;
    color: var(--primary-black);
}
.textbanner p{
    line-height: 1.75rem;
    letter-spacing: 0px;
    font-weight: 400;
    font-size: 1rem;
    color: var(--primary-gray);
}
.rabutton{
    background: var(--primary-gradient);
    color: #fff ;
    font-size: 1.1rem ;
    font-weight: 600;
    padding: 0.8rem 1.8rem;
    border-radius: 5rem;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;

}
.ralogin{

    background: var(--second-gradient);
    color: #fff ;
    font-size: 1.1rem ;
    font-weight: 600;
    padding: 0.8rem 1.8rem;
    border-radius: 5rem;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;

}
.rabutton:hover{
    box-shadow: 0px 4px 9px 1px #ccdcf1;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}
.ralogin:hover{
    box-shadow: 0px 4px 9px 1px #ccdcf1;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}
.carousel-indicators li{
    height: 6px !important;
    border-radius: 3px !important;
    background-color: var(--primary-gray) !important;
}
.carousel-indicators .active{
    background-color: var(--primary-orange) !important;
}
.carousel-indicators{
    bottom: -40px !important;
}
.bannertext{
    margin-top: 12%;
}
.bannertext h1{
    font-size: 2.5rem;
    color: var(--primary-black);
    font-family: var(--main-font);
    font-weight: 700;
    margin-bottom: 2rem;
}
.bannertext p{
    font-size: 1.25rem;
    color: #454545;
    font-family: var(--main-font);
    font-weight: 400;
    margin-bottom: 3rem;
    line-height: 2.25rem;
}

/* //////////////////// Services //////////////////// */

.services{
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 55% auto;
    padding: 6rem 0;
}
.sertitle h2{
    font-size: 1.125rem;
font-weight: 600;
color: var(--primary-orange);
line-height: 2rem;
}

.sertitle h3{
    font-size: 2.25rem;
    line-height: 3.25rem;
    font-weight: 700;
    color: var(--primary-black);
}


.wrap {
    display: flex;
    background: white;
    padding: 1.2rem 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 7px 20px 0px rgb(42 22 139 / 8%);
    margin-bottom: 2rem;
}
.wrap .mbr-iconfont, .wrap .text-wrap h2, .wrap .text-wrap p{
    transition: all .35s cubic-bezier(.38,3,.57,1.6);
    transform: translate3d(0px,0,0);
}

.wrap:hover .mbr-iconfont, .wrap:hover .text-wrap h2, .wrap:hover .text-wrap p{
    transform: translate3d(-6px,0,0);
}

.ico-wrap {
    margin: auto;
}


.mbr-iconfont {
    font-size: 3.5rem !important;
    color: #313131;
    margin: 1rem;
    padding-right: 1rem;
}
.text-wrap h2{
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 0;

}
.text-wrap p{
    line-height: 1.5rem;
    font-size: 0.9rem;
    color: #696969;
    margin-bottom: 0;
}
/* .wrap:hover {
    background: var(--primary-gradient) ;
    color: white;
    box-shadow: 0px 7px 20px 0px #bcd8f3;
}
.wrap:hover .mbr-iconfont{
    color: white;
}

.wrap:hover .text-wrap p{
    color: #fff;
}
.wrap:hover .ico-wrap img svg{
    fill: #fff;
} */
.audiolist{
    background: #fff;
    box-shadow: 0px 18px 15px 0px #E9EFF7;
    border-radius: 0.6rem;
    padding: 1.5rem 2.5rem;
    
}
.audiolist h4{
    font-size: 1.1rem;
    color: #404040;
    font-weight: 500;
    margin: 0;
}
.audiolist span{
    font-size: 1rem;
    color: #898989;
}
.sertitle p{
    font-size: 1rem;
    color: #6A6A6A;
    line-height: 1.6rem;
}
.featuerbg{
    position: absolute;
    right: -23%;
    top: 0;
}
.carousel .slide-left {
    animation: inherit;
}

/* //////////////////// insection //////////////////// */
.insection{
    padding: 5rem 0;
    background-size: cover;
    margin-top: 3.5rem;
}
.rainner h2{
color: #fff;
font-size: 3rem;
}
.rainner a, .rainner span{
    color: #fff;
font-size: 1rem;
}
.ban12{
    transition: all .35s cubic-bezier(.38,3,.57,1.6);
    transform: translate3d(0px,0,0);
}
.ban13{
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all .35s cubic-bezier(.38,3,.57,1.6);
    transform: translate3d(0px,0,0);
}
.ban12:hover{
    transform: translate3d(-5px,0,0);
}
.ban13:hover{
    transform: translate3d(15px,0,0);
}

.ourcomp > li{
    padding-bottom: 10px;
    list-style: none;
    position: relative;
}
.ourcomp > li > h4{
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--primary-black);
}
.ourcomp > li > p{
    margin-bottom: 0;
}
.bluecer{
    position: absolute !important;
    left: -40px;
    top: 45px;
    color: #0849b7;
    transition: all .35s cubic-bezier(.38,3,.57,1.6);
    transform: translate3d(0px,0,0);
}
.redcer{
    position: absolute !important;
    left: -40px;
    top: 45px;
    color: #ee5c36;
    transition: all .35s cubic-bezier(.38,3,.57,1.6);
    transform: translate3d(0px,0,0);
}


.ourcomp > li:hover .bluecer, .ourcomp > li:hover .redcer{
    transform: translate3d(-6px,0,0);
}
.mspace{
    margin-left: -50px;
    margin-right: 50px;
}
.mspacer{
    margin-left: 50px;
    margin-right: -50px;
}
.ban20 {
    position: absolute;
    width: 460px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.racercle{
    position: absolute;
    left: 50%;
    top: 15%;
    font-size: 0.6rem;
    color: #00edc1;

}
.ralinecercle{
    position: absolute;
    left: 80%;
    top: 7%;
    font-size: 1.3rem;
    color: #fff;
    border: 3px solid #ff326d;
    border-radius: 50%;
    
}
.ban6{
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: rotate(190deg);
    height: 40px;
}

.featureser{
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2rem 0;
}

/* /////////////////// Clint ///////////////////// */

.clientreview{
    border: 1px solid #E3E3E3;
    background: #fff;
    border-radius: 1rem;
    box-shadow: 0px 0px 20px #0000000f; 
    padding: 1.875rem;
    text-align: center;
    margin: 0.56rem;
}
.clientreview img{
    margin: 0 auto;
    width: 88px !important;
    height: 88px;
    object-fit: cover;
    border-radius: 50%;
    background: #fff;
    padding: 2px;
    box-shadow: 0px 0px 20px #d2d2d2;
}
.clientreview h5{
    color: #FF8D1C;
    font-size: 1.25rem;
    font-weight: 700;
    margin-top: 1.5rem;
    font-family: var(--main-font);
}
.clientreview h6{
    font-size: 1rem; 
    color: #4D4D4D;
    font-weight: 600;
    font-family: var(--main-font);
    margin-bottom: 0.8rem;
}
.clientreview p{
    font-size: 0.9rem; 
    color: #717171; 
    line-height: 1.5rem;
    font-family: var(--main-font);
    font-weight: 500;
    margin: 0;
}
.featured.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 40px !important;
}
.featured.owl-theme .owl-dots .owl-dot span{
height: 5px;
width: 27px;
border-radius:0;
}
.featured.owl-theme .owl-dots .owl-dot.active span{
    background: #f1803a !important;
}

.contact{
    background-repeat: no-repeat;
    background-size: cover;
}

/* //////////////////// How it Work ///////////////// */
.howitwork{
    background: var(--primary-gradient);
}
.howtitle h2{
    font-size: 1.125rem;
font-weight: 600;
color: #fff;
line-height: 1.5rem;
margin-bottom: 0;
}

.howtitle h3{
    font-size: 2.25rem;
    line-height: 3.25rem;
    font-weight: 700;
    color: #fff;
}
.howban {
    position: absolute;
    left: -11.5rem;
    top: -12.25rem;
    z-index: 99;
}
.howban2 {
    position: absolute;
    right: -15.5rem;
    top: 3.75rem;
}
.howstep h2{
    font-size: 2.15rem;
    font-weight: 700;
    color: #fff;
    font-family: var(--main-font);
    margin-top: -2rem;
}
.howstep p{
    font-size: 1.3rem;
    font-weight: 600;
    color: #fff;
    font-family: var(--main-font);
    margin-top: 1rem;
}

/* //////////////////// How it Work ///////////////// */

/* //////////////////// JOIN ///////////////// */
.jointext h3{
    font-size: 2.8rem;
    font-weight: 700;
    color: var(--primary-black);
    font-family: var(--main-font);

}
.joinbox{
    background: #ffffffd4;
    padding: 2rem;
    height: 100%;
}
.joinbox h4{
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--primary-black);
    margin-top: 1rem;

}
.joinbox p{
    font-size: 1.2rem;
    font-weight: 300;
    color: #707070;
}




/* //////////////////// JOIN ///////////////// */



/***footer***/
.footer-sec{
     float: left;
    width: 100%;
    position: relative;
    padding: 6.5rem 0 2.5rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
}
.logo-img{
        width: 150px;
}
.footer-1 p{
        padding: 1.25rem 0;
}
.footer-list li{
        position: relative;
    margin-bottom: 8px;
    padding-left: 0;
    list-style: none;
    
}
.footer-list li a{
        font-size: 1rem;
    line-height: 28px;
    transition: all .4s ease-in-out;
    margin-left: 0;
    font-weight: 400;
    color: #fff;
}
.footer-sec-inner h4{
   
    text-transform: capitalize;
    line-height: 32px;
    letter-spacing: 0px;
    font-weight: 700;
  padding-bottom:31px;
    padding-top: 5px;
    font-size: 1.2rem;
  
}
.footer-sec-inner p{
    color: #fff !important; 
}
.social-media-links a{
        margin-right: 8px;
}

.social-media-links{
        margin-top: 0.9rem;
}
.copyright{
    border-top: 1px solid #ffffff6b;
}
.copyright p{
    margin-bottom: 0;
}
.Imgleft{
    position: absolute;
    left: -6%;
    top: 13%;
}
.Imgright{
    position: absolute;
    right: -8%;
    bottom: 10%;
    z-index: 99;
}


/******** common **********/
.banner-sec {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 8rem 0 ;
    position: relative;
  

}


.loginbox {
    position: relative;
    background: #fff;
    box-shadow: 0 15px 50px rgb(0 0 0 / 10%);
    overflow: hidden;
    border-radius: 13px;
    padding: 3rem 1rem;
}
.loginht{
	height: 580px;
}

.left-img {
    position: absolute;
    left: -88px;
    top: 0;
    width: 20%;
}

.right-img {
    position: absolute;
    right: -110px;
    z-index: 9999;
    width: 26%;
    top: 68%;
}

.sec-head {
    text-align: center;
}

.sec-head h6 {
    font-size: 1.2rem;
    color: #FF8D1C;
    margin-bottom: 1%;
    font-family: var(--main-font);
    font-weight: 600;
}

.sec-head h2 {
    font-size: 1.8rem;
    font-weight: 700;
    color: #222222;
    padding-bottom: 1%;
    font-family: var(--main-font);
}

.sec-head p {
    width: 59%;
    font-size: 1rem;
    line-height: 1.6rem;
    display: block;
    margin: 0 auto;
    padding-bottom: 3%;
    font-family: var(--main-font);
}

/************* sign in **************/
.sign-in-sec {
    max-width: 60%;
    margin: 0 auto;
    background: #fff;
}


.signin-signup .loginbox .user {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
}

.signin-signup .loginbox .user .imgBx {
    position: relative;
    width: 40%;
    height: 100%;
    transition: 0.5s;
    background: var(--primary-gradient);
    text-align: center;
    color: #fff;
}


.signin-signup .loginbox .user .formBx {
    position: relative;
    width: 60%;
    height: 100%;
    background: #fff;
    padding: 8% 10%;
    transition: 0.5s;
    text-align: center;
}



.signin-signup .loginbox .user .formBx form input {
    position: relative;
    width: 100%;
    padding: 11px 11px 11px 45px;
    background: #f5f5f5;
    color: #333;
    border: none;
    outline: none;
    box-shadow: none;
    margin: 8px 0;
    font-size: 0.9rem;
    font-weight: 400;
    text-transform: capitalize;
    height: 3rem;
}

.signin-signup .loginbox .user .formBx form input[type='submit'] {
    max-width: 100px;
    background: #677eff;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    transition: 0.5s;
}

.signin-signup .loginbox .user .formBx form .signup {
    position: relative;
    margin-top: 20px;
    font-size: 12px;
    letter-spacing: 1px;
    color: #989898;
    text-transform: uppercase;
    font-weight: 300;
}


.signin-signup .loginbox .signupBx {
    pointer-events: none;
}

.signin-signup .loginbox.active .signupBx {
    pointer-events: initial;
}

.signin-signup .loginbox .signupBx .formBx {
    left: 100%;
}

.signin-signup .loginbox.active .signupBx .formBx {
    left: 0;
}

.signin-signup .loginbox .signupBx .imgBx {
    left: -100%;
}

.signin-signup .loginbox.active .signupBx .imgBx {
    left: 0%;
}

.signin-signup .loginbox .signinBx .formBx {
    left: 0%;
}

.signin-signup .loginbox.active .signinBx .formBx {
    left: 100%;
}

.signin-signup .loginbox .signinBx .imgBx {
    left: 0%;
}

.signin-signup .loginbox.active .signinBx .imgBx {
    left: -100%;
}

.formBx h4 {
    font-size: 1.7rem;
    line-height: 2.3rem;
    color: #555555;
    font-weight: 700;
    margin-bottom: 2.5rem;
}

.formBx a {
    display: inline-block;
    margin: 3% 0;
}

.formBx a i {
    border: 1px solid #EBEBEB;
    padding: 0.9rem 0.9rem;
    border-radius: 50%;
    font-size: 1.2rem;
    color: #000000;
    line-height: 0.8rem;
}

.formBx p a,
.formBx form .form-group a {
    margin: 0 !important;
    color: #989898;
    padding-bottom: 5%;
}

.formBx .form-group {
    position: relative;
}

.formBx .form-group .loginicon {
    position: absolute;
    left: 16px;
    z-index: 1;
    top: 14px;
    color: #989898;
}

.formBx form .form-group a {
    padding-top: 5%;
}

.sec-btn {
    display: inline-block;
    margin: 0 !important;
    padding: 3% 15%;
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    font-weight: 500;
    border-radius: 50px;
    transition: 0.4s;
    text-align: center;
}

.sec-btn:hover {
    color: #fff;
    border-radius: 0;
}

.sec-btn-w {
    padding: 5% 20% !important;
}

.sec-btn-g {
    background: var(--primary-gradient);
    border: 0 !important;
}

.sec-btn-g:hover {
    background: var(--primary-gradient);
    border: 0 !important;
    border-radius: 50px !important;
}

.imgBx-content {
    position: absolute;
    top: 35%;
    bottom: 0;
    padding: 0 14%;
}

.imgBx-content h3 {
    font-size: 1.8rem;
    font-weight: 700;
}

.imgBx-content p {
    padding: 10% 0;
    color: #fff
}

.mobile-signin-signup p a {
    font-weight: 600;
}

/******** tone-sec ********/
.tone-sec-inner {

    align-items: center;
    width: 58%;
    margin: 2% auto 0;
     box-shadow: 0 10px 18px #e9eff7;
    padding: 3% 1% 3% 1%;
    border-radius: 14px;
}

.tone-sec-inner label {
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.tone-sec-inner label input {
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #FAFAFA;
    border-radius: 50%;
    border: 1px solid #e5e5e5;

}

/* On mouse-over, add a grey background color */
.tone-sec-inner label:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.tone-sec-inner label input:checked ~ .checkmark {
    background-color: #ff8d1c;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.tone-sec-inner label input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.tone-sec-inner label .checkmark:after {
    top: 5px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.tone-sec-inner .media-body {
    padding-left: 8%;
    padding-top: 2%;
}

.tone-sec-inner .media-body h5 {
    font-size: 1rem;
    color: #404040;
    line-height: 1.4rem;
    font-weight: 500;
    padding-bottom: 2%;
}

.tone-sec-inner .media-body p {
    font-weight: 500;
}

.tone-sec-inner .play-btn {
    float: right;
}

.play-btn i {
    background: var(--primary-gradient);
    color: #fff;
    padding: 0.7rem 0.7rem;
    border-radius: 50%;
}

/****** upload-record *******/
.upload-record-sec {
    text-align: center;
}

.upload-record-btn {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 1rem;
}

.upload-record-btn a {
    background: #fff;
    box-shadow: 3px 9px 18px 5px #e9eff7;
    padding: 1rem 2.1rem;
    border-radius: 20px;
}

.upload-record-btn a h6 {
    color: #484848;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.3px;
    font-size: 1rem;
    margin-top: 0.8rem;
}

.upload-record-sec .sec-head p {
    width: 46%;
}

.upload-record-sec-inner {
    background: #fff;
    margin-bottom: 1.3rem;
    padding: 1rem;
    box-shadow: 0px 18px 15px 0px #e9eff7;
    border-radius: 10px;

}

.upload-record-sec-inner-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.upload-record-sec-inner h5 {
    font-size: 1rem;
    color: #404040;
    font-weight: 400;
    text-align: left;
    margin-bottom: 0.7rem;
}

.upload-record-sec-inner a i {
    color: #fff;
    font-size: 1.2rem;
    background: #ef6650;
    background: -moz-linear-gradient(top, #ef665063 0%, #fe8e1e8c 100%);
    background: -webkit-linear-gradient(top, #ef665063 0%, #fe8e1e8c 100%);
    background: linear-gradient(to bottom, #ef665063 0%, #fe8e1e8c 100%);
    border-radius: 50%;
    padding: 0.8rem 1rem;

}

.upload-record-sec-inner.active {
    background: var(--primary-gradient);
    color: #fff;
}

.upload-record-sec-inner.active h5 {
    color: #fff;
}

.upload-record-sec-inner.active a i {
   	background: var(--primary-gradient);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
   -webkit-text-fill-color:transparent;
}
.upload-record-sec-inner.active a{
    background: #fff;
    border-radius: 50%;
    opacity: 1;
    color: var(--primary-orange);
}


.create-audio-btn {

    background: #747474;
    /* Old browsers */
    background: -moz-linear-gradient(top, #747474 0%, #2b2b2b 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #747474 0%, #2b2b2b 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #747474 0%, #2b2b2b 100%);
    display: table;
    padding: 1rem 1.5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 50px;
    transform: 0.5s;
}

.create-audio-btn:hover {
    color: #fff;

    background: #2b2b2b;
    /* Old browsers */
    background: -moz-linear-gradient(top, #2b2b2b 0%, #747474 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #2b2b2b 0%, #747474 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #2b2b2b 0%, #747474 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}

/***** record sec ****/

.audio-sec h4 {
    font-size: 3.7rem;
    color: #3C3C3C;
    font-weight: 400;
    padding-top: 6%;
    text-align: center;
}

.audio-sec p {
    font-size: 1.1rem;
    color: #817D7D;
     text-align: center;
     margin-bottom: 0;
}


.audio-sec a.audio-icon {
    background: var(--primary-gradient);
    border-radius: 50%;
    color: #fff;
    font-size: 1.9rem;
    line-height: 80px;
    width: 80px;
    height: 80px;
    display: inline-block;
    margin-top: 8%;
}
.audio-sec .sec-head p{
        width: 47%;
}

.audio-icons .savedel{
        color: #707070;
    font-size: 2.4rem;
}
.play-sec .audio-icons a.play-btn{
        margin: 0 7%;
}
.play-sec .audio-icons a.play-btn i{
        font-size: 1.7rem;
    padding: 1.4rem 1.5rem;
    color: #fff;
}
.audio-icons{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: -15%;
}

/***** subscription ******/
.subs-inner{
        text-align: center;
}
.subs-inner  strong{
        display: block;
    font-size: 2.2rem;
    color: #FF8D1C;
    padding: 4% 0 3%;
}
.subs-inner h6{
    font-size: 1.3rem;
    color: #222222;
    padding-bottom: 1%;
    font-weight: 700;
    font-family: var(--main-font);
}
.subs-inner p{
        font-size: 1rem;
    font-weight: 500;
    font-family: var(--main-font);
}
.subscription-sec .sec-head p{
        width: 66%;

}
.upload-record-sec-inner-info a{
    background: var(--primary-gradient);
    width: 50px;
    height: 50px;
    text-align: center;
    display: block;
    line-height: 50px;
    color: #fff;
    font-size: 1.5rem;
    border-radius: 50%;
    opacity: 0.5;
}

.helpbox .hcercl{
    color: #f1803a;
    margin-right: 14px;
    font-size: 0.8rem;
}
.formBx input {
    width: 100%;
    padding: 11px 11px 11px 42px;
    background: #f5f5f5;
    color: #333;
    border: none;
    outline: none;
    box-shadow: none;
    margin: 8px 0;
    font-size: 0.9rem;
    font-weight: 500;
    /* text-transform: capitalize; */
    height: 45px;
}
.form-control:focus{
    background: #f5f5f5;
}
.forgot{
    color: #878787;
}
.check img{
    cursor: pointer;
}
.iplus{
    background: var(--primary-gradient);
    color: #fff;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    margin-right: 5%;
}
.iplus:hover{
    color: #fff;
}

.audiolist{
    transition: all .35s cubic-bezier(.38,3,.57,1.6);
    transform: translate3d(0px,0,0);
}

.audiolist:hover{
    transform: translate3d(-6px,0,0);
}
.profileimg{
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
}
.camera{
    position: absolute;
    bottom: 0;
    right: 0;
}
.myprofile {
    position: relative;
}
.edit{
    position: absolute;
    right: 0;
    top: 0;
    color: var(--primary-orange);
}
.myprofile h3{
    font-size: 1.5rem;
    color: #222222;
    padding-bottom: 1%;
    margin-top: 4%;
    font-weight: 700;
    font-family: var(--main-font);
}
.myprofile h4{
    font-size: 1.2rem;
    color: #222222;
    font-weight: 500;
    font-family: var(--main-font);
}
.profilp{
    color: var(--primary-orange);
    font-size: 1rem;
}
.myrequest p{
    margin-bottom: 5px;
}
.reopen{
    color: #35b55c !important;
    font-weight: 500;
}
.modal-header{
    border-bottom: none;
}
.modal-footer{
    border-top: none;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #ef6b48;
}

.modal-footer{
    justify-content: center;
    padding-bottom: 2rem;
}
.modal-body{
    padding: 2rem 3rem;
}
.thankyou h2{
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--primary-orange);
    padding-bottom: 5%;
    font-family: var(--main-font);
}   
.thankyou p{
    width: 90%;
    font-size: 1rem;
    line-height: 1.6rem;
    display: block;
    margin: 0 auto;
    padding-bottom: 5%;
}   

.forgot input {
    width: 100%;
    padding: 11px 11px 11px 42px;
    background: #f5f5f5;
    color: #333;
    border: none;
    outline: none;
    box-shadow: none;
    margin: 8px 0;
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: capitalize;
    height: 45px;
}
.forgot .form-group .loginicon {
    position: absolute;
    left: 16px;
    z-index: 1;
    top: 14px;
    color: #989898;
}

.loginbox.loginht > .signupBx{
display:none;
}
.loginbox.loginht.active > .signinBx{
    display:none;
    }

    .loginbox.loginht.active > .signupBx{
        display: flex;
    }