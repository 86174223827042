/* lg */ 
@media (min-width: 1200px) {
	
	


   
   
}


/* md */
@media (min-width: 992px) and (max-width: 1199px) {
   
   
    .navbar-nav > li > a{
        font-size: 0.9rem;
    }

   
   
}



/* xs */
@media (max-width: 767px) {

    .mspace, .mspacer{ margin: 0;margin-bottom: 2rem;}
    .textbanner {padding: 80% 0 0 0;}
    .howban{
        width: 90%;
        top:-8.25rem;
    }
    .howban2{
        width: 100%;
    top: 70%;
    }
    .ban20{
        position: inherit;
    width: 100%;
    left: 0;
    top: 0;
    transform: inherit;
    }
    .joinbox{
        height: auto;
        margin-bottom: 2rem;
    }
    .sertitle p{
        width: 100% !important;
    }
     .right-img,
    .left-img {
        display: none;
    }



    .signin-signup .container .user .formBx {
        padding: 8% 6%;
    }

    .banner-sec .container {
        height: auto;
    }

    .tone-sec-inner .col-md-2 {
        width: 20%;
    }

    .tone-sec-inner .col-md-8 {
        width: 60%;
        padding-left: 0;
        padding-right: 0;
    }

    .tone-sec-inner {
        margin: 9% auto 0;
    }

    .Imgright {
        position: absolute;
        right: -30%;
        bottom: 25%;
        z-index: 99;
        width: 80%;
    }
    .Imgleft {
        position: absolute;
        left: -20%;
        top: 8%;
        width: 80%;
    }
    .upload-record-sec-inner-info img{
        width: 50%;
    }
    .upload-record-btn a{
        padding: 1rem 1.7rem;
    }
    .audiolist{
        padding: 1.1rem 1.2rem;
    }
    .audiolist h4{
        font-size: 0.9rem;
    }
    .bluecer, .redcer{
        left: -24px;
    }
    .bannertext h1{
        margin-bottom: 1rem;
        font-size: 2rem;
    }
    .bannertext p{
    margin-bottom: 2rem;
    line-height: 1.7rem;
    font-size: 1.1rem;

    }
    .carousel-indicators {
        bottom: -60px !important;
    }
    .sertitle h3{
        font-size: 1.8rem;
        line-height: 2.5rem;
    }
    .text-wrap h2{
        font-size: 1.1rem;
    }
    .mbr-iconfont{
        padding-right: 0;
    }
    .sertitle {
        padding: 1rem;
    }
    .jointext h3{
        font-size: 2rem;
    }
    .audio-icons{
        justify-content: space-between;
    margin-top: -24%;

    }
    

    /* +++++++++++ Swar +++++++++++++ */
    .right-img,
    .left-img {
        display: none;
    }
/* 
    .signin-signup .loginbox {
        margin: 0 5%;
    } */

    .signin-signup .loginbox .user .formBx {
        padding: 8% 6%;
    }

    .banner-sec .loginbox {
        height: auto;
    }

    .tone-sec-inner .col-md-2 {
        width: 20%;
    }

    .tone-sec-inner .col-md-8 {
        width: 60%;
        padding-left: 0;
        padding-right: 0;
    }

    .tone-sec-inner {
        margin: 9% auto 0;
    }
    .webapplog{
        flex-direction: row;
        align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
    }
   
}


/* XS Portrait */
@media (max-width: 480px) {
    .signin-signup .loginbox .user{
        position: relative;
    }

}

@media (max-width: 991px) {

    /* .banner-sec .loginbox {
        max-width: 500px;
        padding: 9% 3%;
    } */

    .signin-signup .loginbox .imgBx {
        display: none;
    }



    .signin-signup .loginbox .user .formBx {
        width: 100%;
    }

    .right-img,
    .left-img {
        width: 40%;
    }

    /* .banner-sec {
        padding: 2rem 0;
    } */

    .sec-head h6 {
        font-size: 1rem;
    }

    .sec-head p {
        font-size: 0.8rem;
        line-height: 1.2rem;
    }

    .sec-head p,
    .upload-record-sec-inner,
    .tone-sec-inner,
    .upload-record-sec .sec-head p{
        width: 100%;
    }

    .sec-head h2 {
        font-size: 1.2rem;
    }
 .audio-sec h4{
            font-size: 2.5rem;
    }
    .audio-sec .container{
            height: auto;
    }
    .play-sec .audio-icons a.play-btn i{
            font-size: 1.3rem;
    padding: 1rem 1rem;
   
    line-height: 1.2rem;
    }
    .play-sec .audio-icons a i{
            font-size: 1.5rem;
    }
    .subs-inner strong{
            font-size: 1.5rem;
    }
    .subs-inner h6{
            font-size: 1rem;
    }
    .subs-inner p{
            font-size: 0.8rem;
    }
    .webapplog{
        flex-direction: row;
        align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
    }


}





