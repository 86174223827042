
/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */



 .slide-bottom {
	-webkit-animation: slide-bottom 6000ms cubic-bezier(0.550, 0.085, 0.680, 0.530) infinite alternate-reverse both;
	        animation: slide-bottom 6000ms cubic-bezier(0.550, 0.085, 0.680, 0.530) infinite alternate-reverse both;
}

 @-webkit-keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(80px);
              transform: translateY(80px);
    }
  }
  @keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(80px);
              transform: translateY(80px); 
    }
  }
  
  .slide-right {
	-webkit-animation: slide-right 6000ms cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate-reverse both;
	        animation: slide-right 6000ms cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate-reverse both;
}

@-webkit-keyframes slide-right {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  }
  @keyframes slide-right {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(50px);
              transform: translateX(50px);
    }
  }

  .slide-left {
	-webkit-animation: slide-left 2000ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-left 2000ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(30px);
              transform: translateX(30px);
    }
    100% {
      -webkit-transform: translateX(-30px);
              transform: translateX(-30px);
    }
  }
  @keyframes slide-left {
    0% {
      -webkit-transform: translateX(30px);
              transform: translateX(30px);
    }
    100% {
      -webkit-transform: translateX(-30px);
              transform: translateX(-30px);
    }
  }




@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}
